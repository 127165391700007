import { map, share } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IrisProjectI, IrisProjectPage, ProjectWithRoles } from '@iris/common/models';
import { ApiUrl, Cacheable, IrisBaseCRUDService, IrisQueryApiClient, IrisQueryParams, IrisQueryParamsBuilder } from '@iris/api-query';
import { TranslateService } from '@ngx-translate/core';
import { IrisMFChecklist } from '../modules/module-filter/models/IrisMFChecklist';
import { IrisMFMultiselect } from '../modules/module-filter/models/IrisMFMultiselect';
import { IrisMFContains } from '../modules/module-filter/models/IrisMFContains';
import { IrisProjectTypeI } from '../models/irisProjectType';
import { IrisMFBetween } from '../modules/module-filter/models/IrisMFBetween';
import { IrisTimePipe } from '@iris/common/modules/time';
import { IrisColorService } from '@iris/styles';
import { IrisProjectUserI } from '../models/IrisProjectUser';
import { IrisPage } from '../models/page';
import { CostCenter } from '@iris/modules/projects/common/project-form/models/cost-center';
import { CostCenterSearchingDataI } from '@iris/modules/projects/common/project-form/models/cost-center-searching-data';
import {
  DropboxSyncResult,
} from '@iris/modules/projects/details/settings/components/project-dropbox-integration/project-dropbox-integration.component';

@Injectable({
  providedIn: 'root',
})
@ApiUrl('/projects')
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class IrisProjectsService extends IrisBaseCRUDService<any> implements IrisQueryApiClient<IrisProjectI> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  projectsListFilterMeta: any[] = [];

  constructor(
    private readonly _httpClient: HttpClient,
    private readonly translate: TranslateService,
    private readonly irisTimePipe: IrisTimePipe,
    private readonly projectColors: IrisColorService,
  ) {
    super(_httpClient);

    this.projectsListFilterMeta = [
      new IrisMFContains(
        'name',
        {
          label: this.translate.instant('label.Name'),
          orFields: ['shortName'],
        },
      ),
      new IrisMFMultiselect(
        'countryId',
        {
          formFieldName: 'countryIds',
          directoryFieldName: 'filterCountries',
          label: this.translate.instant('label.Country'),
          fullDescription: true,
        },
      ),
      new IrisMFMultiselect(
        'companyId',
        {
          formFieldName: 'companyIds',
          directoryFieldName: 'filterCompanies',
          label: this.translate.instant('label.Company'),
          fullDescription: true,
        },
      ),
      new IrisMFMultiselect(
        'workflowStateId',
        {
          formFieldName: 'stateIds',
          directoryFieldName: 'filterStates',
          label: this.translate.instant('label.States'),
          fullDescription: true,
        },
      ),
      new IrisMFMultiselect(
        'responsibleUserId',
        {
          formFieldName: 'responsibleUserIds',
          directoryFieldName: 'users',
          valueFieldName: 'fullName',
          label: this.translate.instant('label.ProjectLeader'),
        },
      ),
      new IrisMFMultiselect(
        'contactPersonId',
        {
          formFieldName: 'contactPersonIds',
          directoryFieldName: 'users',
          valueFieldName: 'fullName',
          label: this.translate.instant('label.ContactPerson'),
        },
      ),
      new IrisMFContains('departmentId', { label: this.translate.instant('label.Division') }),
      new IrisMFContains('strathekNumber', { label: this.translate.instant('label.STRAthekNumber') }),
      new IrisMFContains('projectNumber', { label: this.translate.instant('label.ProjectNumber') }),
      new IrisMFMultiselect(
        'projectTypeId',
        {
          formFieldName: 'projectTypes',
          directoryFieldName: 'filterProjectTypes',
          textFieldName: 'label',
          valueFieldName: 'value',
          label: this.translate.instant('label.ProjectTypes'),
          fullDescription: true,
        },
      ),
      new IrisMFContains('shortName', { label: this.translate.instant('label.ShortName') }),
      new IrisMFBetween(
        'from',
        {
          formFieldName: 'from',
          label: this.translate.instant('label.From'),
          stringifyFn: (v) => this.irisTimePipe.transform(v),
        },
      ),
      new IrisMFBetween(
        'to',
        {
          formFieldName: 'to',
          label: this.translate.instant('label.To'),
          stringifyFn: (v) => this.irisTimePipe.transform(v),
        },
      ),
      new IrisMFContains('irisCode', { label: this.translate.instant('label.IrisCode') }),
      new IrisMFContains('ld', { label: this.translate.instant('label.projects.LD') }),
      new IrisMFContains('fco', { label: this.translate.instant('label.projects.FCO') }),
      new IrisMFContains('kost', { label: this.translate.instant('label.projects.KOST') }),
      new IrisMFMultiselect(
        'contractorId',
        {
          formFieldName: 'contractorIds',
          directoryFieldName: 'filterContractors',
          label: this.translate.instant('label.projects.Contractor'),
          fullDescription: true,
        },
      ),
      new IrisMFBetween(
        'orderValue',
        {
          formFieldName: 'orderValue',
          label: this.translate.instant('label.OrderValue'),
        },
      ),
      new IrisMFBetween(
        'cost',
        {
          formFieldName: 'cost',
          label: this.translate.instant('label.projects.TotalBudget'),
        },
      ),
      new IrisMFChecklist(
        'resolution',
        {
          checks: [
            { f: 'open', v: 'null', l: this.translate.instant('label.Open') },
            { f: 'resolved', v: '"RESOLVED"', l: this.translate.instant('label.Resolved') },
            { f: 'rejected', v: '"REJECTED"', l: this.translate.instant('label.Rejected') },
          ],
          label: this.translate.instant('label.Resolution'),
        },
      ),
      new IrisMFChecklist(
        'isActive',
        {
          checks: [
            { f: 'inactive', v: false, l: this.translate.instant('label.NotActive') },
            { f: 'active', v: true, l: this.translate.instant('label.Active') },
          ],
          label: this.translate.instant('label.Active'),
        },
      ),
      new IrisMFChecklist(
        'isArchived',
        {
          checks: [
            { f: 'true', v: true, l: this.translate.instant('label.Archived') },
          ],
          label: this.translate.instant('label.Archived'),
        },
      ),
      new IrisMFMultiselect(
        'escalation',
        {
          formFieldName: 'escalations',
          directoryFieldName: 'allEscalations',
          label: this.translate.instant('label.EscalationLevel'),
          fullDescription: true,
        },
      ),
      new IrisMFMultiselect(
        'progress',
        {
          formFieldName: 'progresses',
          directoryFieldName: 'allProgresses',
          label: this.translate.instant('label.pm.Progress'),
          fullDescription: true,
        },
      ),
      new IrisMFBetween(
        'orderValue',
        {
          formFieldName: 'orderValue',
          label: this.translate.instant('label.OrderValue'),
        }),
      new IrisMFBetween(
        'cost',
        {
          formFieldName: 'cost',
          label: this.translate.instant('label.projects.TotalBudget'),
        }),
      new IrisMFChecklist(
        'priority',
        {
          checks: this.getPriorities().map(p => {
            return { f: p.valueField, v: p.id, l: p.name };
          }),
          label: this.translate.instant('label.Priority'),
        },
      ),
    ];
  }

  public getEscalations() {
    return [
      { id: 'LOW', name: this.translate.instant('label.Low'), icon: 'text-muted fa-arrow-down' },
      { id: 'MEDIUM', name: this.translate.instant('label.Medium'), icon: 'text-success fa-arrow-right' },
      { id: 'HIGH', name: this.translate.instant('label.High'), icon: 'text-danger fa-arrow-up' },
    ];
  }

  public getProgress() {
    return [
      { id: '0', name: '0%' },
      { id: '25', name: '25%' },
      { id: '50', name: '50%' },
      { id: '75', name: '75%' },
      { id: '100', name: '100%' },
    ];
  }

  public getPriorities() {
    return [
      {
        id: 'LOW',
        valueField: 'low',
        name: this.translate.instant('label.Low'),
        color: this.projectColors.colors['$secondaryGrey400'],
        icon: 'text-muted fa-arrow-down',
      },
      {
        id: 'NORMAL',
        valueField: 'normal',
        name: this.translate.instant('label.Normal'),
        color: this.projectColors.colors['$primaryBlue'],
        icon: 'text-success fa-arrow-right',
      },
      {
        id: 'HIGH',
        valueField: 'high',
        name: this.translate.instant('label.High'),
        color: this.projectColors.colors['$errorDark'],
        icon: 'text-danger fa-arrow-up',
      },
    ];
  }

  public getProjectsListCharts() {
    return [
      { id: 'DEPARTMENT', title: this.translate.instant('label.Divisions') },
      { id: 'COUNTRY', title: this.translate.instant('label.Countries') },
    ];
  }

  @ApiUrl('~')
  public add(project: IrisProjectI, params: IrisQueryParams = new IrisQueryParams()): Observable<IrisProjectI> {
    return this.httpClient.post<IrisProjectI>(this.url(), project, { params: params.toObject() });
  }

  @ApiUrl('~/{id}')
  public saveProject(project: IrisProjectI, params: IrisQueryParams = new IrisQueryParams()): Observable<IrisProjectI> {
    return this.httpClient.post<IrisProjectI>(this.url({ id: project.id }), project, { params: params.toObject() });
  }

  @ApiUrl('~/by-strathek-number/{strathekNumber}')
  getProjectByStrathekNumber(strathekNumber: string, params: IrisQueryParams = new IrisQueryParams()): Observable<IrisProjectI> {
    return this.httpClient.get<IrisProjectI>(this.url({ strathekNumber }), { params: params.toObject() });
  }

  @Cacheable()
  public getProjectById(id: number): Observable<IrisProjectI>  {
    return this.getById(id);
  }

  getProjects(params: IrisQueryParams = new IrisQueryParams()): Observable<IrisProjectI[]> {
    const queryParams = new IrisQueryParamsBuilder(params)
      .filter('archived', [false])
      .toStructure();

    return this.getAll(queryParams).pipe(
      share({
        connector: () => new ReplaySubject(1),
        resetOnError: false,
        resetOnComplete: false,
        resetOnRefCountZero: false,
      }),
    );
  }

  @ApiUrl('~/page')
  getProjectsPage(params: IrisQueryParams = new IrisQueryParams()): Observable<IrisProjectPage> {
    const queryParams = new IrisQueryParamsBuilder(params)
      .filter('archived', [false])
      .toObject();

    return this.httpClient.get<IrisProjectPage>(this.url(), { params: queryParams }).pipe(
      share({
        connector: () => new ReplaySubject(1),
        resetOnError: false,
        resetOnComplete: false,
        resetOnRefCountZero: false,
      }),
    );
  }
  @ApiUrl('~/get-with-post-filter')
  fetchProjectsByIds(params: IrisQueryParams = new IrisQueryParams()): Observable<IrisProjectPage> {
    return this.httpClient.post<IrisProjectPage>(this.url(), params);
  }

  @ApiUrl('~/my')
  getMyProjects(params: IrisQueryParams = new IrisQueryParams()): Observable<IrisPage<ProjectWithRoles>> {
    const queryParams = new IrisQueryParamsBuilder(params)
      .filter('archived', [false])
      .toObject();

    return this. httpClient.get<IrisPage<ProjectWithRoles>>(this.url(), { params: queryParams });
  }

  @ApiUrl('~/workflow-states-statistics')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getWorkflowStateStatistics(projectIds: number[]): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.httpClient.post<any>(this.url(), projectIds);
  }

  @ApiUrl('~/charts-data')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getChartsData(params: IrisQueryParams = new IrisQueryParams()): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.httpClient.get<any>(
      this.url(),
      {
        params: new IrisQueryParamsBuilder(params)
          .urlParam(
            'chartNames',
            this.getProjectsListCharts().map(({ id }) => id),
          )
          .toObject(),
      },
    );
  }

  // function of generation data for highchart series
  // dataArray - array which used to generate new series
  // property - field from element of data array which used for accumulating data
  // return result array with data ready to input in highchart
  groupEntitiesForWidgets(dataArray, property, useColor?) {
    const result = [];
    dataArray.forEach(element => {
      if (element[property]) {
        const elementName = element[property].name || element[property];
        const elementInResult = result.find(p => p.name === elementName);
        if (!elementInResult) {
          result.push({ name: elementName, y: 1, color: useColor && element[property].color ? element[property].color : null });
        } else {
          elementInResult.y++;
        }
      } else {
        if (!result[0] || result[0].name != this.translate.instant('label.NotSet')) {
          result.unshift({
            name: this.translate.instant('label.NotSet'),
            color: this.projectColors.colors['$grey250Disabled'],
            y: 1,
          });
        } else {
          result[0].y++;
        }
      }
    });
    return result;
  }

  public getProjectsListFilterMeta() {
    return this.projectsListFilterMeta;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public fetchProjectsListFilterEntities(projects: any[], field: string, noStateLabel?: string): any[] {
    if (!projects?.length) {
      return [];
    }

    const result = [];

    if (noStateLabel != null) {
      result.push({
        id: -1,
        name: noStateLabel,
      });
    }

    projects.forEach(project => {
      if (project[field]?.id && !result.find(s => s.id === parseInt(project[field].id, 10))) {
        result.push({
          id: project[field].id,
          name: project[field].name,
        });
      }
    });
    return result;
  }

  @ApiUrl('/project-type')
  getProjectTypes(params: IrisQueryParams = new IrisQueryParams()): Observable<IrisProjectTypeI[]> {
    return this.httpClient.get<IrisProjectTypeI[]>(this.url(), { params: params.toObject() });
  }

  @ApiUrl('/project-type/{id}')
  getProjectTypeById(id: number, params: IrisQueryParams = new IrisQueryParams()): Observable<IrisProjectTypeI> {
    return this.httpClient.get<IrisProjectTypeI>(this.url({ id }), { params: params.toObject() });
  }

  @ApiUrl('/project-type')
  getDefaultProjectType(params: IrisQueryParams = new IrisQueryParams()): Observable<IrisProjectTypeI> {
    params = new IrisQueryParamsBuilder(params)
      .clearLimit().limit(1)
      .clearFilter().filter('isDefault', [true])
      .toStructure();

    return this.httpClient.get<IrisProjectTypeI[]>(this.url(), { params: params.toObject() }).pipe(
      map(types => types.length ? types[0] : null),
    );
  }

  @ApiUrl('/project-type/active/toggle/{id}')
  setActiveProjectType(id: number): Observable<void> {
    return this.httpClient.post<void>(this.url({ id }), {});
  }

  @ApiUrl('/project-type/{id}/change-default/{value}')
  setDefaultProjectType(id: number, value = true): Observable<IrisProjectTypeI> {
    return this.httpClient.post<IrisProjectTypeI>(this.url({ id, value }), {});
  }

  @ApiUrl('/project-type/{id}')
  deleteProjectType(id: number, value = true): Observable<void> {
    return this.httpClient.delete<void>(this.url({ id, value }));
  }

  @ApiUrl('/project-type/{id}')
  saveProjectType(projectType: IrisProjectTypeI): Observable<IrisProjectTypeI> {
    return this.httpClient.post<IrisProjectTypeI>(this.url( { id: projectType.id }), projectType);
  }

  @ApiUrl('~/users/{userId}/project-users')
  public getProjectUsersByUser(userId: number, params = new IrisQueryParams()): Observable<IrisProjectUserI[]> {
    return this.httpClient.get<IrisProjectUserI[]>(this.url({ userId }), { params: params.toObject() });
  }

  @ApiUrl('~/{projectId}/project-users/{projectUserId}')
  public deleteProjectUser(projectId: number, projectUserId: number): Observable<IrisProjectUserI> {
    return this.httpClient.delete<IrisProjectUserI>(this.url({ projectId, projectUserId }));
  }

  @ApiUrl('~/{projectId}/project-users/{userId}')
  public updateProjectUser(projectUser: IrisProjectUserI): Observable<IrisProjectUserI> {
    return this.httpClient.post<IrisProjectUserI>(this.url({ projectId: projectUser.projectId, userId: projectUser.id }), projectUser);
  }

  @ApiUrl('/users/{userId}/current-project-user')
  public getCurrentProjectUserByUserId(userId: number): Observable<IrisProjectUserI> {
    return this.httpClient.get<IrisProjectUserI>(this.url({ userId }));
  }

  public getProjectViewUrl(id: number): string {
    return `/ui/ui/projects-list/project/${id}/view/general`;
  }

  @ApiUrl('~/info')
  public getProjectsPageInfo(params = new IrisQueryParams()): Observable<IrisProjectPage> {
    const queryParams = new IrisQueryParamsBuilder(params)
      .filter('archived', [false])
      .toObject();

    return this.httpClient.get<IrisProjectPage>(this.url(), { params: queryParams });
  }

  @ApiUrl('~/info-with-roles')
  public getProjectsPageInfoWithRoles(params = new IrisQueryParams()): Observable<IrisPage<ProjectWithRoles>> {
    const queryParams = new IrisQueryParamsBuilder(params)
      .filter('archived', [false])
      .toObject();

    return this.httpClient.get<IrisPage<ProjectWithRoles>>(this.url(), { params: queryParams });
  }

  @ApiUrl('~/info/{projectId}')
  @Cacheable()
  public getProjectInfo(projectId: number, params = new IrisQueryParams()): Observable<IrisProjectI> {
    return this.httpClient.get<IrisProjectI>(this.url({ projectId }), { params: params.toObject() });
  }

  public anyProjectAvailable(): Observable<boolean> {
    const params = new IrisQueryParamsBuilder()
      .limit(1)
      .onlyFields(['id'])
      .toStructure();

    return this.httpClient.get<IrisProjectI[]>(this.url(), { params: params.toObject() }).pipe(
      map(project => !!project),
    );
  }

  query(queryParams?: IrisQueryParams): Observable<IrisProjectI[]> {
    return this.getProjects(queryParams);
  }

  @ApiUrl('/exchange/project/{projectId}/company/name')
  public getExecutionCompanyName(projectId: number): Observable<string> {
    return this.httpClient.get<{ companyName: string }>(this.url({ projectId })).pipe(
      map(data => data?.companyName),
    );
  }

  @ApiUrl('/exchange/project/{projectId}/company/logo')
  public getCompanyLogoForAProject(projectId: number): Observable<string> {
    return this.httpClient.get<{ image: string }>(this.url({ projectId })).pipe(
      map(data => data?.image),
    );
  }

  @ApiUrl('/exchange/hierarchy/validate')
  public validateCostCenter(kostCenter: CostCenter): Observable<CostCenter> {
    return this.httpClient.post<CostCenter>(this.url(), kostCenter);
  }

  @ApiUrl('/exchange/search/kost')
  public searchCostCenters(searchingData: CostCenterSearchingDataI): Observable<CostCenter[]> {
    return this.httpClient.post<CostCenter[]>(this.url(), searchingData);
  }

  @ApiUrl('~/{projectId}/dropbox-integration')
  activateDropboxSync(projectId: number): Observable<DropboxSyncResult> {
    return this.httpClient.post<DropboxSyncResult>(this.url({ projectId }), {});
  }

  @ApiUrl('~/{id}/complete')
  setProjectPhaseComplete(id: number, params: Partial<IrisProjectI>): Observable<IrisProjectI> {
    return this.httpClient.post<IrisProjectI>(this.url({ id }), params);
  }

  @ApiUrl('~/{id}/warranty')
  setProjectPhaseWarranty(id: number, params: Partial<IrisProjectI>): Observable<IrisProjectI> {
    return this.httpClient.post<IrisProjectI>(this.url({ id }), params);
  }

  @ApiUrl('~/{id}/archive')
  setProjectPhaseArchive(id: number): Observable<void> {
    return this.httpClient.post<void>(this.url({ id }), {});
  }
}
